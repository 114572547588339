import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsConfig from './aws-exports';

import '../src/assets/traduction/i18n';
import '@aws-amplify/ui-react/styles.css';
import './assets/styles/index.scss';

import Home from './pages/Home';
import FileRepository from './pages/FileRepository';
import Supplier from './pages/Supplier';
import Reference from './pages/Reference';
// import CreateReference from './pages/Reference/create';
import Login from './pages/Login';
import Error404 from './pages/Error/404';
import Error403 from './pages/Error/403';
import DepositTracking from './pages/DepositTracking';
// import FormatFormRepository from "./pages/Format/repository";
// import UpdateReference from './pages/Reference/update';
import CreateFormat from "./pages/Format/create";
import IsAuth from './components/Authentification/isAuth';
import CheckUserGroup from './components/Authentification/checkUserGroup';
import getDataProject from './components/Authentification/getDataProject'
import refreshToken from './components/Authentification/refreshToken'
// import GetFormat from "./pages/Format/GetFormat";
import AddSupplier from "./pages/Supplier/add-supplier";
import FormatsBySupplier from "./pages/Format/by-supplier";

Amplify.configure(awsConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));

async function fetchUserGroup(setUserGroup) {
  try {
    const group = await CheckUserGroup();
    setUserGroup(group);
  } catch (error) {
    console.error(error);
  }
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [userGroup, setUserGroup] = useState("Disconnected");
  const [dataProject, setDataProject] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const updateData = async () => {
      await fetchUserGroup(setUserGroup);
      const newDataProject = await getDataProject();
      setDataProject(newDataProject);
      if (userGroup === "Disconnected" || userGroup === "Disconnected user") {
        navigate("/connexion")
      }
    };

    updateData();

    const intervalId = setInterval(refreshToken, 3300000);
    return () => clearInterval(intervalId);
  }, [location.pathname, userGroup]);

  return (
    <IsAuth>
      <Routes>
        <Route path="/" element={<Home userGroup={userGroup} dataProject={dataProject} />} />
        <Route path="/referentiel" element={<Reference userGroup={userGroup} dataProject={dataProject} />} />
        <Route path="/catalogues-fournisseur" element={<Supplier userGroup={userGroup} dataProject={dataProject} />} />
        <Route path="/connexion" element={<Login userGroup={userGroup} />} />
        {userGroup === 'client' ? (
          <>
            <Route path="/403" element={<Error403 />} />
            <Route path="/upload-file" element={<Error403 />} />
            <Route path="/suivi-depot" element={<Error403 />} />
            <Route path="/creation-format" element={<Error403 />} />
            <Route path="/creation-fournisseur" element={<Error403 />} />
            <Route path="/format-fournisseur" element={<Error403 />} />
            {/* 
            <Route path="/depot-format" element={<Error403 />} />
            <Route path="/trouver-format" element={<Error403 />} />
            <Route path="/creation-reference" element={<Error403 />} />
            <Route path="/modification-reference/:reference/:supplier" element={<Error403 />} /> 
            */}
          </>
        ) : (
          <>
            <Route path="/upload-file" element={<FileRepository userGroup={userGroup} dataProject={dataProject} />} />
            <Route path="/suivi-depot" element={<DepositTracking userGroup={userGroup} dataProject={dataProject} />} />
            <Route path="/creation-format" element={<CreateFormat userGroup={userGroup} dataProject={dataProject} />} />
            <Route path="/creation-fournisseur" element={<AddSupplier userGroup={userGroup} dataProject={dataProject} />} />
            <Route path="/format-fournisseur" element={<FormatsBySupplier userGroup={userGroup} dataProject={dataProject} />} />
            {/* 
            <Route path="/depot-format" element={<FormatFormRepository userGroup={userGroup} dataProject={dataProject} />} />
            <Route path="/trouver-format" element={<GetFormat userGroup={userGroup} dataProject={dataProject} />} />
            <Route path="/creation-reference" element={<CreateReference userGroup={userGroup} dataProject={dataProject} />} />
            <Route path="/modification-reference/:reference/:supplier" element={<UpdateReference userGroup={userGroup} dataProject={dataProject} />} /> 
            */}
          </>
        )}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </IsAuth>
  );
}


root.render(<App />);